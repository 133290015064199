window.Client = {
    init: function () {
        var checkbox = document.querySelector('input[data-label="coreg_optin_usag"]');
        if (checkbox) {
            checkbox.checked = true;
        }
    },

    onRender: function (screenId) {
        // Do something when the flow renders
    }
}

const addDisclaimer = () => {
    const variables = {
        button_text: "FINISH!",
    };

    const script = document.createElement("script");
    script.src = "https://influx.consumerco.com/js/variables.js";

    script.setAttribute(
        "data-keys",
        JSON.stringify(["form_disclaimer_filedisability"])
    );
    script.setAttribute("data-payload", JSON.stringify(variables));
    document.body.appendChild(script);
};

const initJornaya = (formId, leadidLabel = 'leadid_tcpa_disclosure') => {
    const form = document.querySelector(`[data-id="${formId}"]`);

    const i = document.createElement('input');
    i.name = 'leadid_token';
    i.id = 'leadid_token';
    i.type = 'hidden';

    const i2 = document.createElement('input');
    i2.id = leadidLabel;
    i2.name = leadidLabel;
    i2.type = 'hidden';

    form.appendChild(i);
    form.appendChild(i2);
};

window.addEventListener('heyflow-init', () => {
    initJornaya('heyflow-form');
    addDisclaimer();
})